import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";
const CompanyMetrics = {};
(() => {
  const winningMetrics = (CompanyMetrics.winningMetrics = () => {
    $(".wm-toggle-button.wm-graph-button").on("click", (event) => {
      $(".wm-toggle-button.wm-graph-button").addClass("wm-active-toggle-button");
      $(".wm-toggle-button.wm-table-button").removeClass("wm-active-toggle-button");
      $(event.currentTarget).parents(".guild-rail-section").find(".wm-summary-table").hide();
      $(event.currentTarget).parents(".guild-rail-section").find(".graph-container").show();
    });
    $(".wm-toggle-button.wm-table-button").on("click", (event) => {
      $(".wm-toggle-button.wm-table-button").addClass("wm-active-toggle-button");
      $(".wm-toggle-button.wm-graph-button").removeClass("wm-active-toggle-button");
      $(event.currentTarget).parents(".guild-rail-section").find(".wm-summary-table").show();
      $(event.currentTarget).parents(".guild-rail-section").find(".graph-container").hide();
    });
    const labels = ["Net Promoter Score", "30 Day Retention (%)", "Customer Acquisition Cost ($)", "Customer Acquisition Doubling Time (Days)", "Annual Recurring Revenue ($)"];
    const winningMetrics = $("#winning-metrics").data("winning-metrics-data");
    const clip = {
      left: 3,
      top: false,
      right: 4,
      bottom: false,
    };
    const data = {
      datasets: [
        {
          type: "line",
          label: labels[0],
          backgroundColor: "#FEAD3C",
          borderColor: "#FEAD3C",
          data: winningMetrics[0],
          order: 1,
          clip: clip,
        },
        {
          type: "line",
          label: labels[1],
          backgroundColor: "#67CEFF",
          borderColor: "#67CEFF",
          data: winningMetrics[1],
          order: 2,
          clip: clip,
        },
        {
          type: "line",
          label: labels[2],
          backgroundColor: "#00C977",
          borderColor: "#00C977",
          data: winningMetrics[2],
          order: 3,
          clip: clip,
        },
        {
          type: "line",
          label: labels[3],
          backgroundColor: "#B24AE9",
          borderColor: "#B24AE9",
          data: winningMetrics[3],
          order: 4,
          clip: clip,
        },
        {
          type: "line",
          label: labels[4],
          backgroundColor: "#EA3A89",
          borderColor: "#EA3A89",
          data: winningMetrics[4],
          order: 5,
          clip: clip,
        },
      ],
    };
    const config = {
      data: data,
      options: {
        maintainAspectRatio: true,
        layout: {
          padding: 4,
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (context) => {
                const d = new Date(context[0].parsed.x);
                const formattedDate = d.toLocaleString([], {
                  month: "short",
                  year: "numeric",
                  day: "numeric",
                });
                if (d.toISOString().split("T")[0] == new Date().toISOString().split("T")[0]) {
                  return "Today";
                }
                return formattedDate;
              },
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
              maxTicksLimit: 10
            },
          },
          x: {
            ticks: {
              maxTicksLimit: 10,
            },
            type: "time",
            time: {
              unit: "week",
              isoWeekday: true,
              displayFormats: {
                week: "MMM d",
              },
            },
            min: get6thWeekBefore(new Date()),
            max: winningMetrics[0][0].x,
          },
        },
      },
    };
    const myChart = new Chart($("#winning-metrics-graph")[0], config);
    setupDateSelection($("#winning-metrics-graph"), config.options.scales.x.min, config.options.scales.x.max, winningMetrics[0][winningMetrics[0].length - 1].x, winningMetrics[0][0].x, config, myChart);
  });

  const followerMetrics = (CompanyMetrics.followerMetrics = () => {
    const followerData = $("#followers").data("follower-data");
    const color = $("#followers").data("company-color");
    const companyCreationDate = new Date($("#followers").data("company-creation-date") + "T12:00:00");
    const data = {
      datasets: [
        {
          type: "line",
          label: "Followers",
          backgroundColor: color,
          borderColor: color,
          data: followerData,
          order: 1,
          clip: {
            left: 3,
            top: false,
            right: 4,
            bottom: false,
          },
        },
      ],
    };
    const config = {
      data: data,
      options: {
        maintainAspectRatio: true,
        layout: {
          padding: 4,
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: (context) => {
                const d = new Date(context[0].parsed.x);
                const formattedDate = d.toLocaleString([], {
                  month: "short",
                  year: "numeric",
                  day: "numeric",
                });
                if (d.toISOString().split("T")[0] == new Date().toISOString().split("T")[0]) {
                  return "Today";
                } else if (d.toISOString().split("T")[0] == companyCreationDate.toISOString().split("T")[0]) {
                  return "Founded on: " + formattedDate;
                }
                return formattedDate;
              },
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              maxTicksLimit: 10,
              stepSize: 1,
            },
          },
          x: {
            ticks: {
              maxTicksLimit: 10,
            },
            type: "time",
            time: {
              unit: "week",
              isoWeekday: true,
              displayFormats: {
                week: "MMM d",
              },
            },
            min: get6thWeekBefore(new Date()),
            max: followerData[followerData.length - 1].x,
          },
        },
      },
    };

    const myChart = new Chart($("#followers-over-time-graph")[0], config);
    setupDateSelection($("#followers-over-time-graph"), config.options.scales.x.min, config.options.scales.x.max, companyCreationDate.toISOString().split("T")[0], new Date().toISOString().split("T")[0], config, myChart);
  });
  const get6thWeekBefore = (date) => {
    if (date.getDay() === 1) {
      date.setDate(date.getDate() - 7 * 5);
    } else {
      while (date.getDay() !== 1) {
        date.setDate(date.getDate() - 1);
      }
      date.setDate(date.getDate() - 7 * 5);
    }
    return date.toISOString().split("T")[0];
  };
  const setupDateSelection = (container, initialStart, initialEnd, minDate, maxDate, config, chart) => {
    const startDateInput = container.parent(".graph-container").find("#graph-date-start");
    const endDateInput = container.parent(".graph-container").find("#graph-date-end");
    const allButton = container.parent(".graph-container").find(".graph-all-button");
    const resetButton = container.parent(".graph-container").find(".graph-reset-button");
    startDateInput.val(initialStart);
    endDateInput.val(initialEnd);
    startDateInput.attr("max", maxDate);
    startDateInput.attr("min", minDate);
    endDateInput.attr("max", maxDate);
    endDateInput.attr("min", minDate);

    startDateInput.on("change", () => {
      const changedDate = startDateInput.val();
      config.options.scales.x.min = changedDate;
      chart.update();
    });
    endDateInput.on("change", () => {
      const changedDate = endDateInput.val();
      config.options.scales.x.max = changedDate;
      chart.update();
    });
    allButton.on("click", () => {
      config.options.scales.x.min = minDate;
      config.options.scales.x.max = maxDate;
      startDateInput.val(minDate);
      endDateInput.val(maxDate);
      chart.update();
    });
    resetButton.on("click", () => {
      config.options.scales.x.min = initialStart;
      config.options.scales.x.max = initialEnd;
      startDateInput.val(initialStart);
      endDateInput.val(initialEnd);
      chart.update();
    });
  };
})();
document.addEventListener("turbolinks:load", () => {
  console.log("company-metrics.js load");
  if ($("#winning-metrics-graph").length > 0) {
    CompanyMetrics.winningMetrics();
  }
  if ($("#followers-over-time-graph").length > 0) {
    CompanyMetrics.followerMetrics();
  }
});

import Cookies from 'js-cookie'

$(document).on("click", "#cookies-accept", (e) => {
    Cookies.set('allow_cookies', 'yes', {
        expires: 365
    });
    $("#cookies-bar").addClass('hidden');
})

$(document).on("click", "#cookies-reject", (e) => {
    Cookies.set('allow_cookies', 'no', {
        expires: 365
    });
    $("#cookies-bar").addClass('hidden');
})
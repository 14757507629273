var timer = undefined;

const assembleFilterParams = (container) => {
    const params = new URLSearchParams();

    params.append('type', container.attr('data-model-type'));
    params.append('offset', 0);

    $(container).find('input').each((index, input) => {
        const name = $(input).attr('name');
        const value = $(input).val();
        if (value && value.length > 0) {
            params.append(name, value);
        }
    });
    return params.toString();
}
$(document).on('keyup', '.data-view-header-search input', function(event) {
    if (timer) {
        window.clearTimeout(timer);
        timer = undefined;
    }

    if (event.key === 'Escape') {
        $(event.currentTarget).val("");
    }

    timer = window.setTimeout(function() {
        timer = undefined;

        const searchContainer = $(event.currentTarget).parents('.data-view-header-search');
        const tableBody = searchContainer.siblings('.data-view-body');

        const params = assembleFilterParams(searchContainer);

        $.get(`/guild/data_view/row_page?${params}`, (data) => {
            tableBody.html(data);
            console.log("Table Updated", params);
        });

    }, 250);

});

$(document).on('click', '.data-view-value', (event) => {
    const container = $(event.currentTarget).parents('.data-view-editable-value');
    const editor = container.find('.data-view-editor');
    editor.css('display', 'flex');
    $(event.currentTarget).css('display', 'none');
    editor.find('input').focus();
    editor.find('input').select();
});

$(document).on('click', '.data-view-editor-action-save', (event) => {
    const form = $(event.currentTarget).parents('.data-view-editor');
    const container = form.parents('.data-view-editable-value');

    form.on('ajax:success', (event) => {
        container.replaceWith(event.originalEvent.detail[2].response);
    });
    form.on('ajax:error', (event) => {
        console.log("ERROR", event);
        alert(`Could not save value: ${event.originalEvent.detail[0].message}`);
    });


    form.find("input[type='submit']").click();
});

$(document).on('click', '.data-view-editor-action-cancel', (event) => {
    const container = $(event.currentTarget).parents('.data-view-editable-value');
    const editor = container.find('.data-view-editor');
    const value = container.find('.data-view-value');
    editor.css('display', 'none');
    value.css('display', 'block');
});

import * as UserSummaryPopup from 'packs/user-summary-popup.js'
//import ImageResize from "packs/image-resize.min"; //changed one line in file. File source is now in javascript/packs.
import ImageCompress from 'quill-image-compress';
// import QuillResizeModule from 'packs/resize'; //added if statement in node_modules/quill-resize-module/src/QuillResize.js 
import Quill from "quill";
import MagicUrl from 'quill-magic-url'
import {Mention, MentionBlot} from "quill-mention";
import BlotFormatter from 'quill-blot-formatter-mobile'; //https://github.com/juandjara/quill-blot-formatter-mobile

export default Quill;

//Quill.register("modules/imageResize", ImageResize);
Quill.register('modules/imageCompress', ImageCompress);
Quill.register('modules/magicUrl', MagicUrl);
// Quill.register('modules/imageResize', QuillResizeModule);
Quill.register('modules/blotFormatter', BlotFormatter);
Quill.register({ "blots/mention": MentionBlot, "modules/mention": Mention });

export const QuillEditors = {};

(() => {
  const toolbarOptions = {
    container: [
      ["bold", "italic", "underline"],
      [{
        align: []
      }],
	  [{ 'indent': '-1'}, { 'indent': '+1' }],      
      ["image", "link", "blockquote"],
      [{
        color: []
      }, {
        background: []
      }],
      [{
        list: "ordered"
      }, {
        list: "bullet"
      }],
      [{
        size: ["small", false, "large"]
      }],
      ["clean"]
    ]
  };

  const mentionOptions = {
    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
    mentionDenotationChars: ["@"],
  renderItem: function (item, searchTerm) {
	  return (item.summary);
  },
  mentionContainerClass: "ql-mention-list-container guild-user-mentions-container",
    source: function(searchTerm, renderList, mentionChar) {
      let values;
      if (searchTerm.length > 2 && mentionChar === "@") {
        searchUsers(searchTerm).then((data) => {
          values = data;
          const matches = [];
          for (let i = 0; i < values.length; i++) {
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
              matches.push(values[i]);
            }
          }
          renderList(matches, searchTerm);
        });
      } else {
        renderList(null);
      }
    },
  };

  const moduleOptions = {
    mention: mentionOptions,
    toolbar: toolbarOptions,
    imageCompress: {
      quality: 1,
      maxWidth: 1000,
      maxHeight: 1000,
      debug: false,
    },
    blotFormatter: {

    },
    // imageResize: {
    //   parchment: {
    //     image: {
    //       attribute: ['width', 'height'],
    //       limit: {
    //         minWidth: 50,
    //         maxWidth: 1000,
    //         minHeight: 50,
    //         maxHeight: 1000,
    //       }
    //     }
    //   },
    //   styles: {
    //     display: {
    //       border: 'none',
    //       color: 'black'
    //     }
    //   },
    //   modules: ['Resize', 'DisplaySize']
    // },
	magicUrl: true,
  };

  const searchUsers = (QuillEditors.searchUsers = async(query) => {
    const response = await fetch("/guild/users/search_users", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").getAttribute("content"),
      },
      body: JSON.stringify({
        q: query,
      }),
    });
    const data = await response.json();
    return await data.users;
  });

  const quillGetText = (QuillEditors.quillGetText = (quill) => {
    let text = quill
      .getContents()
      .ops.map((op) => {
        if (typeof op.insert === "string") {
          return op.insert;
        }
        if (op.insert.mention) {
          return op.insert.mention.denotationChar + op.insert.mention.value;
        }
      })
      .join("");
    return text;
  });

  const getMentions = (QuillEditors.getMentions = (delta) => {
    let mentions = [];
    for (let i = 0; i < delta.ops.length; i++) {
      if (delta.ops[i].insert.mention !== undefined) {
        mentions.push({
          user_id: parseInt(delta.ops[i].insert.mention.id)
        });
      }
    }
    return JSON.stringify(mentions);
  });

  const isQuillEmpty = (QuillEditors.isQuillEmpty = (delta) => {
    return delta.ops[0].insert === "\n";
  });

  const correctULTagFromQuill = (str) => {
    if (str) {
      let re = /(<ol><li data-list="bullet">)(.*?)(<\/ol>)/;
      let strArr = str.split(re);

      while (
        strArr.findIndex((ele) => ele === '<ol><li data-list="bullet">') !== -1
      ) {
        let indx = strArr.findIndex(
          (ele) => ele === '<ol><li data-list="bullet">'
        );
        if (indx) {
          strArr[indx] = '<ul><li data-list="bullet">';
          let endTagIndex = strArr.findIndex((ele) => ele === "</ol>");
          strArr[endTagIndex] = "</ul>";
        }
      }
      return strArr.join("");
    }
    return str;
  };

  const quillAddUrlMatcher = (QuillEditors.quillAddUrlMatcher = (quill) => {
    quill.clipboard.addMatcher(Node.TEXT_NODE, function(node, delta) {
      let regex = /https?:\/\/[^\s]+/g;
      if (typeof node.data !== "string") return;
      let matches = node.data.match(regex);
      if (matches && matches.length > 0) {
        let ops = [];
        let str = node.data;
        matches.forEach(function(match) {
          let split = str.split(match);
          let beforeLink = split.shift();
          ops.push({
            insert: beforeLink
          });
          ops.push({
            insert: match,
            attributes: {
              link: match
            }
          });
          str = split.join(match);
        });
        ops.push({
          insert: str
        });
        delta.ops = ops;
      }
      return delta;
    });
  });

  const enableQuill = (QuillEditors.enableQuill = (quill, quillToolbar, quillContainer, quillEditor, saveButton) => {
    $(quillToolbar).removeClass("hidden");
    $(quillContainer).removeClass("quill-comment-border");
    $(quillEditor).removeClass("quill-comment-editor-paddingleft");
    $(saveButton).removeClass("hidden");
    quill.enable();
  });

  const disableQuill = (QuillEditors.disableQuill = (quill, quillToolbar, quillContainer, quillEditor, saveButton) => {
    $(quillToolbar).addClass("hidden");
    $(quillContainer).addClass("quill-comment-border");
    $(quillEditor).addClass("quill-comment-editor-paddingleft");
    if (saveButton !== null) $(saveButton).addClass("hidden");
    quill.disable();
  });

  const showError = (QuillEditors.showError = (message, XMLHttpRequest, errorThrown) => {
    if ($(message).hasClass(".guild-delete-comment-alert")) {
      $(message).html(`Comment not deleted, an error occurred: ${XMLHttpRequest.status} ${errorThrown}`);
      $(message).removeClass(".guild-delete-comment-alert");
    } else {
      if (XMLHttpRequest.responseJSON !== undefined && XMLHttpRequest.responseJSON.text === "Text required to post comment") {
        $(message).html(`Comment not saved, an error occurred: ${XMLHttpRequest.responseJSON.text}`);
      } else {
        $(message).html(`Comment not saved, an error occurred: ${XMLHttpRequest.status} ${errorThrown}`);
      }
    }
    $(message).removeClass("hidden");
  });

  const showSuccess = (QuillEditors.showSuccess = (message) => {
    $(message).hasClass("guild-save-comment-alert") ? $(message).html("Comment Saved") : $(message).html("Comment Posted");
    $(message).removeClass("hidden");
    $(message).addClass("guild-comment-alert-success");
    $(message).fadeOut(2500, function() {
      $(this).removeClass("guild-comment-alert-success");
      $(this).css("display", "inline");
      $(this).addClass("hidden");
    });
  });

  const commentPostStart = QuillEditors.commentPostStart = (commentPostContainer, message) => {
    commentPostContainer.children(".guild-comment-loader").removeClass("hidden");
    commentPostContainer.children(".guild-comment-loader-message").html("Comment Uploading...").removeClass("hidden");
    (message) ? message.html(""): commentPostContainer.children(".guild-save-comment-error-alert").html("");
  }

  const commentPostEnd = QuillEditors.commentPostEnd = (commentPostContainer) => {
    commentPostContainer.children(".guild-comment-loader").addClass("hidden");
    commentPostContainer.children(".guild-comment-loader-message").addClass("hidden");
  }

  // Initialize Quill on the specified container, checking that it's not already there (user has hit back/foward)
  const createEditor = QuillEditors.createEditor = (container, operations, placeholder) => {
    const quillToolbar = $(container).parent().find('.ql-toolbar');

    if (quillToolbar.length > 0) {
      return;
    }
    
    const quill = new Quill(container, {
      modules: moduleOptions,
      placeholder: placeholder || "Write a comment...",
      theme: "snow",
    });
    
    quillAddUrlMatcher(quill);
    
    if (operations) {
      quill.setContents(operations);
    }
    
	if ($(container).attr('data-initial-focus')) {
	 	quill.focus();
	}
    
    return (quill);
  }

  const setupQuillRichTextEditor = (QuillEditors.setupQuillRichTextEditor = (quillTextContainer, commentForm) => {
    if (!quillTextContainer) {
      return (false);
    }
    
    let quill = new Quill(quillTextContainer, {
      modules: moduleOptions,
      placeholder: "Write a comment...",
      theme: "snow",
    });
    
    quillAddUrlMatcher(quill);
    
	if ($(container).attr('data-initial-focus')) {
		quill.focus();
	}
    
    $(commentForm).on("submit", function(event) {
      console.log("FORM SUBMIT");
      let commentForm = $(this);
      let postMessage = commentForm.children(".guild-comment-post-container").children(".guild-post-comment-alert");
      let data = new FormData(this);
      data.append("body", JSON.stringify(removeExtraSpaces(quill.getContents())));
      data.append("body_HTML", correctULTagFromQuill(quill.root.innerHTML));
      data.append("body_text", quillGetText(quill));
      data.append("mentions", getMentions(quill.getContents()));
      getImageBlobs(quill, data);
      $.ajax({
        method: $(this).attr("method"),
        url: $(this).attr("action"),
        data: data,
        contentType: false,
        processData: false,
        success: function(data) {
          const commentList = commentForm.parents(".comment-list").first();
          
          if (commentList.length > 0) {
            replaceComments(data.html_data, commentList);
          } else {
            $(document).trigger({type: "edge:new", edgeHTML: data});
            quill.setText('');
          }
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          showError(postMessage, XMLHttpRequest, errorThrown);
          $('input[type="submit"]').prop("disabled", false);
        },
        beforeSend: function() {
          commentPostStart(commentForm.children(".guild-comment-post-container"), postMessage);
        },
        complete: function() {
          commentPostEnd(commentForm.children(".guild-comment-post-container"));
        }
      });
      event.preventDefault();
    });
  });
  
  const extractFromEditor = QuillEditors.extractFromEditor = (container, textChange=false) => {
    const quill = Quill.find(container, true);
    if (!textChange){
      let formattedBody = removeExtraSpaces(quill.getContents());
      quill.setContents(formattedBody);
    }
    return ({
      body: JSON.stringify(removeExtraSpaces(quill.getContents())),
      body_HTML: correctULTagFromQuill(quill.root.innerHTML),
      body_text: quillGetText(quill),
      mentions: getMentions(quill.getContents()),
      blobs: extractImageBlobs(quill)
    });
  }
  
  const clearEditor = QuillEditors.clearEditor = (container) => {
    const quill = Quill.find(container, true);
    quill.setText('');
  }
  
  const disableEditor = QuillEditors.disableEditor = (container) => {
    const quill = Quill.find(container, true);
    quill.disable();
  }

  const setEditorText = QuillEditors.setEditorText = (container, text) => {
    const quill = Quill.find(container, true);
    quill.setText(text);
    quill.focus();
    quill.setSelection(quill.getLength());
  }
  
  const setupEditor = QuillEditors.setupEditor = (commentForm) => {
    const container = $(commentForm).find("#quill-text-container").first()[0];
    
    return (setupQuillRichTextEditor(container, commentForm));
  }

  const replaceComments = QuillEditors.replaceComments = (dataJSON, commentList) => {
    $(commentList).replaceWith(dataJSON);
    setupQuillCommentBodies();
    //setupQuillRichTextEditor($("#quill-text-container").first()[0], $('form.comment-form').first());
  };

  const findTopCommentList = QuillEditors.findTopCommentList = (commentElement) => {
    let commentList = null;
    while (commentElement.length > 0) {
      if (commentElement.hasClass("comment-list")) {
        commentList = commentElement;
      }
      commentElement = commentElement.parent();
    }
    return commentList;
  }

  const setupQuillCommentBody = QuillEditors.setQuillCommentBody = (container) => {
    const commentBody = $(container).find(".comment-body").first()[0];
    const commentBodyHidden = $(container).find(".comment-body-hidden").first();

    if (!commentBody) {
      return (false);
    }

    const quill = new Quill(commentBody, {
      modules: moduleOptions,
      theme: "snow",
    });

    quillAddUrlMatcher(quill);

    if (commentBodyHidden) {
      const commentBodyHiddenHTML = $(commentBodyHidden).html();
      if (String(commentBodyHiddenHTML).includes('{"ops":[{"')) {
        quill.setContents(JSON.parse(commentBodyHiddenHTML));
      } else {
        quill.setText(commentBodyHiddenHTML);
      }
      const quillToolbar = $(commentBody).parent().children(".ql-toolbar");
      const quillContainer = $(commentBody);
      const quillEditor = $(commentBody).children(".ql-editor");
      disableQuill(quill, quillToolbar, quillContainer, quillEditor, null);
    }
  }

  const setupQuillCommentBodies = QuillEditors.setupQuillCommentBodies = () => {
    const commentBody = $(".comment-body");
    const commentBodyHidden = $(".comment-body-hidden");
    var index = 0;
    commentBody.each(function() {
      let commentBody = $(this)[0];
      if (commentBody) {
        let quill = new Quill(commentBody, {
          modules: moduleOptions,
          theme: "snow",
        });
        quillAddUrlMatcher(quill);
        let commentBodyHiddenDiv = commentBodyHidden.eq(index);
        if (commentBodyHiddenDiv) {
          let commentBodyHiddenHTML = $(commentBodyHiddenDiv).html();
          if (String(commentBodyHiddenHTML).includes('{"ops":[{"')) {
            quill.setContents(JSON.parse(commentBodyHiddenHTML));
          } else {
            quill.setText(commentBodyHiddenHTML);
          }
          let quillToolbar = $(commentBody).parent().children(".ql-toolbar");
          let quillContainer = $(commentBody);
          let quillEditor = $(commentBody).children(".ql-editor");
          disableQuill(quill, quillToolbar, quillContainer, quillEditor, null);
        }
      }
      index++;
    });
  }

  const removeExtraSpaces = QuillEditors.removeExtraSpaces = (delta) => {
    delta.forEach((ops) => {
      if (ops.insert.image === undefined && ops.insert.mention === undefined) {
        ops.insert = ops.insert.replace(/\u00A0+/g, ' ');
        ops.insert = ops.insert.replace(/\n\u0020+/g, "\n");
        ops.insert = ops.insert.replace(/\u0020+\n/g, "\n");
        
      }
    });
    return delta;
  }

  const setupReplyComments = (QuillEditors.setupReplyComments = () => {
    $(document).on("click", ".comment_reply_action", (event) => {
      const comment = $(event.currentTarget).parents(".single-comment").first();
      const commentForm = comment.find(".comment-form").first();
      commentForm.toggleClass("comment-reply-hidden");
      let quillContainer = $(commentForm).children("#quill-container");
      let quillToolbar = $(quillContainer).children(".ql-toolbar");
      if (quillToolbar.length === 0) {
        let quillTextContainer = $(quillContainer).children("#quill-text-container")[0];
        setupQuillRichTextEditor(quillTextContainer, commentForm);
      }
    });
  });

  const setupEditButton = (QuillEditors.setupEditButton = () => {
    $(document).on("click", ".guild-edit-comment-action", (event) => {
      let parents = $(event.currentTarget).parentsUntil(".comment-list");
      let quillContainer = $(parents).children(".comment-body.ql-container");
      let quillToolbar = $(parents).children(".ql-toolbar");
      let saveButton = $(parents).children(".guild-comment-save-container").children(".guild-save-comment-action");
      let quillEditor = $(parents).children(".comment-body.ql-container").children(".ql-editor");
      let quill = Quill.find(quillContainer[0]);
      if ($(quillToolbar).hasClass("hidden")) {
        enableQuill(quill, quillToolbar, quillContainer, quillEditor, saveButton);
      } else {
        disableQuill(quill, quillToolbar, quillContainer, quillEditor, saveButton);
      }
    });
  });

  const setupSaveButton = QuillEditors.setupSaveButton = () => {
    $(document).on("click", ".guild-save-comment-action", (event) => {
      event.preventDefault();
      
      let parents = $(event.currentTarget).parentsUntil(".comment-list");
      let saveMessage = $(parents).children(".comment-byline").children(".guild-save-comment-alert");
      let saveErrorMessage = parents.children(".guild-comment-save-container").children(".guild-save-comment-error-alert");
      let quillContainer = $(parents).children(".comment-body.ql-container");
      let quillToolbar = $(parents).children(".ql-toolbar");
      let saveButton = $(parents).children(".guild-comment-save-container").children(".guild-save-comment-action");
      let quillEditor = $(parents).children(".comment-body.ql-container").children(".ql-editor");
      let quill = Quill.find(quillContainer[0]);
      let data = $(parents).closest("div").find("input,select,textarea").serializeArray();
      
      data.push({
        name: "body",
        value: JSON.stringify(removeExtraSpaces(quill.getContents()))
      });
      data.push({
        name: "body_HTML",
        value: correctULTagFromQuill(quill.root.innerHTML)
      });
      data.push({
        name: "body_text",
        value: quillGetText(quill)
      });
      
      $.ajax({
        method: "PATCH",
        url: `/guild/comments/${data.find((x) => x.name === "commentable_id").value}`,
        data: data,
        dataType: "JSON",
        success: function() {
          showSuccess(saveMessage);
          disableQuill(quill, quillToolbar, quillContainer, quillEditor, saveButton);
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          showError(saveErrorMessage, XMLHttpRequest, errorThrown);
        },
        beforeSend: function() {
          commentPostStart(parents.children(".guild-comment-save-container"), null);
          $(saveButton).prop("disabled", true);
        },
        complete: function() {
          commentPostEnd(parents.children(".guild-comment-save-container"));
          $(saveButton).prop("disabled", false);
        }
      });
    });
  }

  const setupDeleteButton = (QuillEditors.setupDeleteButton = () => {
    $(document).on("click", ".guild-delete-comment-action", (event) => {
      if (confirm("Are you sure?")) {
        event.preventDefault();
        let parents = $(event.currentTarget).parentsUntil(".comment-list");
        let deleteMessage = $(parents).children(".comment-byline").children(".guild-save-comment-alert").addClass(".guild-delete-comment-alert");
        let data = $(event.currentTarget).closest("div").find("input,select,textarea").serializeArray();
        let commentDelete = $(event.currentTarget);
        $.ajax({
          method: "DELETE",
          url: `/guild/comments/${data.find((x) => x.name === "commentable_id").value}`,
          data: data,
          success: function(dataJSON) {
            replaceComments(dataJSON, $(event.currentTarget).parents(".comment-list").first());
          },
          error: function(XMLHttpRequest, textStatus, errorThrown) {
            showError(deleteMessage, XMLHttpRequest, errorThrown);
          },
        });
        event.preventDefault(); // Prevent link from following its href
      }
    });
  });

  const setupUpvote = QuillEditors.setupUpvote = () => {
    $(document).on("ajax:success", ".upvote-button", (event) => {
      const [data, status, xhr] = event.detail;
      const icon = $(event.target).children(".upvote-icon").first();
      const count = $(event.target).children(".like-count").first();
      count.html(data.count);
	  if (data.liked) {
	    icon.addClass("upvote-icon-voted");
	  } else {
		icon.removeClass("upvote-icon-voted");
	  }
    });
  }

  const getImageBlobs = Quill.getImageBlobs = (quill, data) => {
    const imgs = Array.from(quill.container.querySelectorAll('img[src^="data:"]:not(.loading)'));
    let blobs = []
    for (let img of imgs) {
      let blobData = getBlobData(img.getAttribute("src"));
      blobs.push(blobData);
    }
    blobs.forEach((blob) => data.append("blobs[]", blob));
  }
  
  const extractImageBlobs = Quill.extractImageBlobs = (quill) => {
    const imgs = Array.from(quill.container.querySelectorAll('img[src^="data:"]:not(.loading)'));
    const blobs = [];
    for (let img of imgs) {
      let blobData = getBlobData(img.getAttribute("src"));
      blobs.push(blobData);
    }

    return (blobs);
  }

  function getBlobData(base64Str) {
    if (typeof base64Str !== "string" || base64Str.length < 100) {
      return base64Str;
    }
    const data = b64ToBlobData(base64Str); // your upload logic
    return data;
  }

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    let blob = new Blob(byteArrays, {
      type: contentType
    });
    return blob;
  }

  function b64ToBlobData(base64) {
    let block = base64.split(";");
    let contentType = block[0].split(":")[1];
    let realData = block[1].split(",")[1];
    let blob = b64toBlob(realData, contentType);
    return blob;
  }

  const setupClickMentionedUser = QuillEditors.setupClickMentionedUser = () => {
    $(document).on('click', '.mention', (event) => {
      const userId = $(event.currentTarget).attr('data-id');
      location.href = `${window.location.protocol}//${window.location.host}/guild/users/${userId}`;
    });
  }

  const setupAllEditors = QuillEditors.setupAllEditors = () => {
    const quillTextContainer = $("#quill-text-container").first()[0];
    const quillToolbar = $('.ql-toolbar'); //checks if toolbar is already loaded on to the page. if user uses forward and back nav in browser.
    if (quillTextContainer && quillToolbar.length === 0) {
      let commentForm = $('form.comment-form').first();
      QuillEditors.setupQuillRichTextEditor(quillTextContainer, commentForm); //first comment form
    }
    if (quillToolbar.length === 0) {
      QuillEditors.setupQuillCommentBodies(); //all other comments
    }
  }
  
  // Show the first editor in a content stream (rest remain hidden until reply is hit)
  const showFirstEditor = QuillEditors.showFirstEditor = () => {
    QuillEditors.setupQuillRichTextEditor($("#quill-text-container").first()[0], $('form.comment-form').first());
  }
  
  const hookAllEvents = QuillEditors.hookAllEvents = () => {
    QuillEditors.setupEditButton(); //when edit it pressed
    QuillEditors.setupSaveButton(); //when save button is pressed
    QuillEditors.setupDeleteButton(); //when delete button is pressed
    QuillEditors.setupReplyComments(); //when reply button is pressed
    QuillEditors.setupUpvote();
    QuillEditors.setupClickMentionedUser();
  }
})();
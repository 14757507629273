const Companies = {};

(() => {
    require("jquery")


    const isElementVisible = Companies.isElementVisible = (element) => {
        var topView = $(window).scrollTop();
        var botView = topView + $(window).height();
        var topElement = $(element).offset().top;
        var botElement = topElement + $(element).height();
        return ((botElement <= botView) && (topElement >= topView));
    }

    const waitToSeeProgressBar = Companies.waitToSeeProgressBar = () => {
        $(document).scroll(function () {
            $( ".guild-progress-bar-fill" ).each(function() {
                const element = document.querySelector(".guild-progress-bar-fill"); //check for div, so error are not thrown in console
                if(element){
                    isInView = isElementVisible($(this));
                    if(isInView && !$(this).hasClass('Starting')){ //stops from executing more than once on the scroll.
                        $(this).addClass('Starting');
                        zeroToProgressBar($(this));
                    }
                }   
            });
        });
    }


    const zeroToProgressBar = Companies.zeroToProgressBar = () => {
        const element = document.querySelector(".guild-progress-bar-fill");
        element.style.opacity = "1";
        if(element){
            $(element) // this will set the width of the bar to 0 then animate it to the desired width
                .data("width", $(element).width())
                .width(0)
                .animate({width: $(element).data("width")},1200);
        }
    }
})();


document.addEventListener("turbolinks:load", () => {
    console.log("companies.js load");
    const element = document.querySelector(".guild-progress-bar-fill");
    if(element)
        if(Companies.isElementVisible(element)){
            Companies.zeroToProgressBar(); //run animation if the progress bar is already in the view.
        } else{
            Companies.waitToSeeProgressBar(); //this method will wait till the progress bar is in the view, then run the animation.
        }
  });
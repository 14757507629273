import {remoteRefresh} from 'packs/remote';
import {QuillEditors} from 'packs/quill-editor';

// Handle any pre-loaded thread initialization
$(document).on("turbolinks:load", (event) => {
  const form = $(".guild-comment-form-editor").first();
  if (form.length) {
	  QuillEditors.createEditor(form[0]);
  }
  navigateToComment();
});


// A comment thread has loaded
$(document).on("thread:load", (event) => {	
  const form = $(event.refreshedElement).find(".guild-comment-form-editor").first();

  const editor = QuillEditors.createEditor(form[0]);

  navigateToComment();
  
  editor.focus();
});

// Navigate to the specific comment if specified by the cid parameter in the URL
const navigateToComment = () => {
	const highlightId = highlightCommentId();
	if (highlightId) {
		const comment = findComment(highlightId);
	  	if (comment.length > 0) {
			const replyAction = comment.find('.comment_reply_action');
			if (!replyAction.hasClass('login_needed')) {
				replyToComment(comment);
			}
		  return;
	  	}
  	}
}

// A new Comment has arrived, so insert it in the right place
$(document).on("comment:new", (event) => {
  const newComment = $(event.commentHTML);
  const list = findListForCommentable(newComment.attr("data-parent-type"), newComment.attr("data-parent-id"));
  const form = list.find(".guild-comment-form").first();
  console.log("newComment", event.commentHTML, newComment, list, form);

  newComment.insertAfter(form);
  
	// Hide form unless top-level comment
  if (list.parents(".comment-list").length > 0) {
	  form.addClass("hidden");
  }
});

// A Comment has been deleted, so find the corresponding element and remote it from the DOM
$(document).on("comment:deleted", (event) => {
  const comment = findComment(event.commentId);
  
  comment.fadeOut(400, () => {
    comment.remove();
  });
});


// Post a new Comment and display it in the thread
const postComment = (editorData) => {
  $.post({
    url: '/guild/comments',
    data: JSON.stringify(editorData),
    contentType: "application/json",
    processData: false,
    success: (data) => {
      $(document).trigger({type: "comment:new", commentHTML: data.commentHTML});
      QuillEditors.clearEditor(findListForCommentable(editorData.commentable_type, editorData.commentable_id).find(".guild-comment-form-editor")[0]);
		$(".comment-post-button.loading").removeClass("loading");
    }
  });
}

// Update an existing Comment
const updateComment = (commentId, editorData) => {
  $.ajax({
    url: `/guild/comments/${commentId}`,
    method: "PATCH",
    data: JSON.stringify(editorData),
    contentType: "application/json",
    processData: false,
    success: (data) => {
      console.log("Updated comment", data);
      const oldComment = findComment(commentId);
      oldComment.replaceWith(data);
    }
  });
}

// Given a Commentable, find the comment list/thread element
const findListForCommentable = (commentableType, commentableId) => {
  return ($(`.comment-list[data-commentable-type="${commentableType}"][data-commentable-id="${commentableId}"]`));
}

// Find a Comment element by Id
const findComment = (commentId) => {
  return ($(`.single-comment[data-comment-id=${commentId}]`));
}

// Return the id of any Comment to highlight
const highlightCommentId = () => {
	const url = new URL(window.location);

	return (url.searchParams.get("cid"));
}

const replyToComment = (comment) => {
    const list = comment.find(".comment-list").first();
    const form = list.find(".guild-comment-form").first();
    form.toggleClass("hidden");
  
    console.log("Reply", comment, list, form);
  
  	if (!form.hasClass("hidden")) {
		const editor = QuillEditors.createEditor(form.find(".guild-comment-form-editor").first()[0]);
		if (editor) {
			editor.focus();
		}
	}
}

// Click on the Post button
$(document).on("click", ".comment-post-button", (event) => {  
  const form = $(event.currentTarget).parents(".guild-comment-form")
  const editor = form.find(".guild-comment-form-editor");
  const editorData = QuillEditors.extractFromEditor(editor[0]);
  
  editorData.commentable_id = form.attr("data-commentable-id");
  editorData.commentable_type = form.attr("data-commentable-type");

	if ($(event.currentTarget).hasClass("loading")) {
		return;
	}
	
	$(event.currentTarget).addClass("loading");

  postComment(editorData);
});

// Click on the Upvote/Like button
$(document).on("ajax:success", ".upvote-button", (event) => {
  const [data, status, xhr] = event.detail;
  const icon = $(event.target).children(".upvote-icon").first();
  const count = $(event.target).children(".like-count").first();
  count.html(data.count);
  console.log("UPVOTE", data);
  
  if (data.liked) {
    icon.addClass("upvote-icon-voted");
  } else {
	icon.removeClass("upvote-icon-voted");
  }
});

// Click on the Edit button
$(document).on("click", ".guild-edit-comment-action", (event) => {
  const comment = $(event.currentTarget).parents(".single-comment").first();
  const commentId = comment.attr("data-comment-id");
  
  $.get(`/guild/comments/${commentId}/edit_form`, (data) => {
    const contentContainer = comment.find(".comment-content-container").first();
    contentContainer.prepend(data);
    contentContainer.find(".comment-content").addClass("hidden");
    contentContainer.find(".comment-byline").addClass("hidden");
    
    const form = comment.find(".guild-comment-form-editor").first();
    const quillOperations = comment.find(".guild-comment-quill-ops").first().text();

    QuillEditors.createEditor(form[0], JSON.parse(quillOperations));
  });
});

// Click on Delete
$(document).on("click", ".guild-delete-comment-action", (event) => {
  const actions = $(event.currentTarget).parents(".comment-byline");
  actions.find(".guild-delete-confirm-action").parent().removeClass("hidden");
  actions.find(".guild-delete-cancel-action").parent().removeClass("hidden");
  actions.find(".guild-delete-comment-action").parent().addClass("hidden");
});

// Confirm Delete
$(document).on("click", ".guild-delete-confirm-action", (event) => {
  const commentId = $(event.currentTarget).parents(".single-comment").first().attr("data-comment-id");

  $.ajax({
    method: "DELETE",
    url: `/guild/comments/${commentId}`,
    success: function(data) {
      $(document).trigger({type: "comment:deleted", commentId: commentId});      
    },
    error: function(XMLHttpRequest, textStatus, errorThrown) {
    },
  });
});

// Cancel Delete
$(document).on("click", ".guild-delete-cancel-action", (event) => {
  const actions = $(event.currentTarget).parents(".comment-byline");
  actions.find(".guild-delete-confirm-action").parent().addClass("hidden");
  actions.find(".guild-delete-cancel-action").parent().addClass("hidden");
  actions.find(".guild-delete-comment-action").parent().removeClass("hidden");
});

// Click on the Save Changes button
$(document).on("click", ".comment-save-button", (event) => {  
  const comment = $(event.currentTarget).parents(".single-comment").first();
  const commentId = comment.attr("data-comment-id");
  const form = comment.find(".guild-comment-form").first();
  const editor = form.find(".guild-comment-form-editor").first();
  const editorData = QuillEditors.extractFromEditor(editor[0]);
  QuillEditors.disableEditor(editor[0]);
  
  updateComment(commentId, editorData);
});

// Click on the Reply button
$(document).on("click", ".comment_reply_action", (event) => {
	replyToComment($(event.currentTarget).parents(".single-comment").first());
});

// CLick on a mentioned user
$(document).on('click', '.mention', (event) => {
  const userId = $(event.currentTarget).attr('data-id');
  location.href = `${window.location.protocol}//${window.location.host}/guild/users/${userId}`;
});

$(document).on('click', '.suggested-comment', (event) => {
    const text = $(event.target).text();
    console.log("Suggested question clicked", text);
    const form = $(".guild-comment-form-editor").first();
    if (form.length) {
        QuillEditors.setEditorText(form[0], text);
    }
});

import {remoteRefresh} from 'packs/remote';

$(document).on("click", ".guild-interests-selector-interest-action", (event) => {
    $.post($(event.target).attr("data-url"), (data) => {
		console.log(data);
		$(event.target).toggleClass('guild-button-secondary');
		remoteRefresh($(event.target).siblings('.guild-interests-selector-people').first());
	});``
});

// Click to invite someone
$(document).on("click", ".guild-invitation-modal .guild-button-secondary", (event) => {
  $(event.target).parents(".guild-interests-invite-container").first().hide();
});

// Invitation successful
$(document).on("ajax:success", ".guild-invitation-modal form", (event) => {
    $(event.target).parents(".guild-interests-invite-container").first().hide();
	remoteRefresh($(event.target).parents(".guild-interests-selector-row").find('.guild-interests-selector-people-invited').first());
});


const Surveys = {};

const replaceChoices = Surveys.replaceChoices = (html, surveyId) => {
  $(`#choices-of-survey-${surveyId}`).replaceWith(html);
};

$(document).on("click", ".guild-survey-vote-action", (event) => {
  const surveyId = $(event.currentTarget).attr("data-survey-id");
  $.get($(event.currentTarget).attr("data-url"), (data) => {
    replaceChoices(data, surveyId);
  }).fail((jqXHR) => {
    if (jqXHR.status === 401) {
      window.location.pathname = "/guild/signup";
    }
  });
});

$(document).on("ajax:success", ".guild-survey-form", (event) => {
  console.log("Success sdfasd");
  const [_data, _status, xhr] = event.detail;
  replaceChoices(xhr.responseText, event.currentTarget.id);
  $('.guild-survey-form > *[name="choice"]').val('');
});

$(document).on("click", ".survey-choice-delete-action", (event) => {
  $.get($(event.currentTarget).attr("data-url"), (data) => {
    replaceChoices(data, $(event.currentTarget).attr("data-survey-id"));
  });
  event.stopPropagation();
});

document.addEventListener("turbolinks:load", () => {
  console.log("surveys.js load");
});
const calculatePaddingLeft = (popupWidth, userNameWidth) => {
  const summaryPopupMidpoint = popupWidth / 2;
  const userNameMidpoint = userNameWidth / 2;
  const moveLeft = summaryPopupMidpoint - userNameMidpoint;
  return moveLeft;
};

const getAndDisplayUserInformation = (summaryPopup, userNameId, userNamePositionTop, userNamePositionLeft, userNameWidth, userName) => {
  $.ajax({
    method: "GET",
    url: `/guild/users/user_info/${userNameId}`,
    success: function (data) {
      $(summaryPopup).removeClass("guild-opacity-none");
      $(summaryPopup).html(data);
      const summaryPopupWidth = parseInt($(summaryPopup).css("width"), 10);
      const moveLeft = calculatePaddingLeft(summaryPopupWidth, userNameWidth);
      const positions = checkIfElementFitsInCurrentWindow(userNamePositionTop, userNamePositionLeft - moveLeft, summaryPopup, userName);
      $(summaryPopup).css({
        top: positions[0],
        left: positions[1],
      });
      setupFollow(summaryPopup);
    },
    error: function (XMLHttpRequest, textStatus, errorThrown) {},
  });
};

const checkIfElementFitsInCurrentWindow = (userNamePositionTop, userNamePositionLeft, summaryPopup, userName) => {
  const summaryPopupHeight = parseInt(summaryPopup.height(), 10);
  const summaryPopupWidth = parseInt(summaryPopup.width(), 10);
  const userNameDOMRect = userName.getBoundingClientRect();
  const windowHeight = $(window).height();
  const windowWidth = $(window).width();
  let newTop = userNamePositionTop;
  let newLeft = userNamePositionLeft;
  if (userNameDOMRect.top + summaryPopupHeight + 2 * userNameDOMRect.height >= windowHeight) {
    newTop -= summaryPopupHeight;
    if ($(userName).hasClass("guild_user_link") || ($(userName).is("a") && $(userName).parent(".guild-update").length !== 0)) {
      newTop += userNameDOMRect.height * 3.4;
    } else if ($(userName).is("a") && $(userName).parent(".guild-edge-header").length !== 0) {
      newTop += userNameDOMRect.height;
    }
  } else {
    if ($(userName).hasClass("guild_user_link") || ($(userName).is("a") && $(userName).parent(".guild-update").length !== 0)) {
      newTop += userNameDOMRect.height * 4.8;
    } else if ($(userName).is("a") && $(userName).parent(".guild-edge-header").length !== 0) {
      newTop += userNameDOMRect.height * 2.1;
    } else {
      newTop += userNameDOMRect.height * 1.2;
    }
  }
  if (userNamePositionLeft <= 0) {
    newLeft = 10;
  } else if (userNamePositionLeft + summaryPopupWidth + 10 >= windowWidth) {
    newLeft -= userNamePositionLeft + summaryPopupWidth - windowWidth + 10;
  }
  const positions = [newTop, newLeft];
  return positions;
};

const mobileCheck = () => {
  let check = false;
  (function (a) {
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

if (!mobileCheck()) {
  $(document).on("mouseover", '.mention, a[href*="guild/users/"]',  function () { //if this is () => it doesnt work...
    if ($(".guild-user-summary-popup").length === 0) {
      let userNamePositionLeft;
      let userNamePositionTop;
      let userNameId = $(this).attr("data-user-popup-id");
      if (userNameId) {
        userNamePositionLeft = $(this).offset().left;
        userNamePositionTop = $(this).offset().top;
      } else if ($(this).hasClass("guild_user_link") || ($(this).is("a") && ($(this).parent(".guild-edge-header").length !== 0 || $(this).parent(".guild-update").length !== 0))) {
        userNameId = $(this).attr("href").toString().split("users/")[1];
        userNamePositionLeft = $(this).position().left;
        userNamePositionTop = $(this).position().top;
      } else if ($(this).hasClass("mention")) {
        userNameId = $(this).attr("data-id");
        userNamePositionLeft = $(this).offset().left;
        userNamePositionTop = $(this).offset().top;
      } else {
        return;
      }
      let summaryPopup = document.createElement("div");
      $(summaryPopup).addClass("guild-user-summary-popup").addClass("guild-opacity-none");
      $(document.body).append(summaryPopup);
      summaryPopup = $(".guild-user-summary-popup");
      getAndDisplayUserInformation(summaryPopup, userNameId, userNamePositionTop, userNamePositionLeft, parseInt($(this).css("width"), 10), this);
    }
  });

  $(document).on("mouseout", ".guild-user-summary-popup", (event) => {
    const summaryPopup = $(".guild-user-summary-popup");
    const outsideHoverElement = $(event.relatedTarget);
    if (outsideHoverElement.parents(".guild-user-summary-popup").length === 0 && !(outsideHoverElement.is("span") && outsideHoverElement.parent(".mention").length !== 0) && !outsideHoverElement.is('a[href*="guild/users/"]') && outsideHoverElement.parents(".guild-edge-header-image").length === 0) {
      $(summaryPopup).remove();
    }
  });
  
  $(document).on("mouseout", '.mention, a[href*="guild/users/"]', (event) => {
    const summaryPopup = $(".guild-user-summary-popup");
    const outsideHoverElement = $(event.relatedTarget);
    if (summaryPopup.length !== 0 && outsideHoverElement.parents(".guild-user-summary-popup").length === 0 && outsideHoverElement.parents(".guild-edge-header-image").length === 0 && !(outsideHoverElement.is("span") && outsideHoverElement.parent(".mention").length !== 0)) {
      $(summaryPopup).remove();
    }
  });
}

const setupFollow = (summaryPopup) => {
  let followButton = $(summaryPopup).children(".guild-user-avatar-and-link-container").children(".guild-user-follow-summary-popup").children("div").children(".follow-button");
  followButton.on("ajax:success", (event) => {
    const [data, status, xhr] = event.detail;
    const button = $(followButton).children("input").first();
    const followCount = $(summaryPopup).children(".guild-user-information-summary-popup").children("#guild_user_follower_count").children(".guild-user-information-list-item");
    if (data.count === 1 && followCount.length === 0) {
      let tempElement = $(summaryPopup).children(".guild-user-information-summary-popup").children("#guild_user_member_since").clone(true, true);
      $(tempElement).attr("id", "guild_user_follower_count");
      $(summaryPopup).children(".guild-user-information-summary-popup").append(tempElement);
      $(tempElement).children(".guild-user-information-list-item").children(".material-icons").text("follow_the_signs");
      $(tempElement).children(".guild-user-information-list-item").children(".guild_summary_text").text("1 follower");
    } else {
      data.count > 0 ? $(followCount).children(".guild_summary_text").text(`${data.count} followers`) : $(followCount).remove();
    }
    if (data.following) {
      button.removeClass("guild-button-secondary");
      button.addClass("guild-button");
      button.attr("value", "\u2713 Following");
    } else {
      button.removeClass("guild-button");
      button.addClass("guild-button-secondary");
      button.attr("value", "Follow");
    }
  });
};

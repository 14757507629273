import consumer from "./consumer"

// consumer.subscriptions.create("ViewUpdateChannel", {
//   connected() {
//     // Called when the subscription is ready for use on the server
//     console.log("ViewUpdateChannel connected");
//   },
//
//   disconnected() {
//     // Called when the subscription has been terminated by the server
//     console.log("ViewUpdateChannel disconnected");
//   },
//
//   received(data) {
//     // Called when there's incoming data on the websocket for this channel
//     console.log("ViewUpdateChannel data received", data);
//   }
// });

// Refresh a specific element from it's declared source (data-source-url)
export const remoteRefresh = (element) => {
    const url = $(element).attr("data-source-url");
    const eventNamespace = $(element).attr("data-event-namespace");

    $.get(url, (data) => {
        const replacement = $(data);
        $(element).replaceWith(replacement);
        observeLoadOnVisible();
        setupWebsocketRefresh();
        $(document).trigger({
            type: `${eventNamespace ? eventNamespace : "remote"}:load`,
            refreshedElement: replacement
        });
    });
}

// Refresh the content of a specific element from it's declared source
export const remoteRefreshContent = (element) => {
    const url = $(element).attr("data-source-url");
    const eventNamespace = $(element).attr("data-event-namespace");

    $.get(url, (data) => {
        const replacement = $(data);
        $(element).html(replacement);
        $(element).trigger({
            type: `${eventNamespace ? eventNamespace : "remote"}:load`,
            refreshedElement: element
        });
    });
}

// Refresh all elements with the specified class
export const remoteRefreshClass = (className) => {
    $(className).each((index, element) => {
        remoteRefresh(element);
    });
}

// provide data-load-on-visible functionality
const intersectionObserver = new IntersectionObserver((entries) => {
    for (let i in entries) {
        let entry = entries[i];
        if (entry.isIntersecting === true) {
            remoteRefresh(entry.target);
            intersectionObserver.unobserve(entry.target);
        }
    }
}, {threshold: [0]});

export const observeLoadOnVisible = () => {
    $("*[data-load-on-visible]").each((index, element) => {
        addIntersectionObserver(element);
    });
}

export const addIntersectionObserver = (node) => {
    if (node.getAttribute('data-observing-intersection')) {
//        console.log("Skipping because already observed", node);
        return;
    }
    intersectionObserver.observe(node);
    node.setAttribute('data-observing-intersection', true);
}


// Start observing the target node for configured mutations
const observeMutation = () => {
    mutationObserver.observe(document, {childList: true, subtree: true});
}

const mutationObserver = new MutationObserver((mutationsList, observer) => {
    for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
            mutation.addedNodes.forEach((node) => {
                if (node.getAttribute && node.getAttribute('data-load-on-visible') !== null) {
                    // console.log("MutuationObserver found an elgible node", node);
                    addIntersectionObserver(node);
                }
            });
        }
    }
});

// $(document).on("ajax:success", (event) => {
//     console.log("ajax:success");
// });
//
// $(document).on("ajax:complete", (event) => {
//     console.log("ajax:complete");
// });

// Refresh the specified parent on success response
$(document).on("ajax:complete", "*[data-refresh-parent-response]", (event) => {
    const selector = $(event.currentTarget).attr("data-refresh-parent-response");
    const html = $(event.originalEvent.detail[0].response);
    const element = $(event.currentTarget).closest(selector);

    if (html && element) {
        $(element).replaceWith($(html));
    }
});

const hookAll = () => {
    observeLoadOnVisible();
    observeMutation();
}

document.addEventListener("turbolinks:load", () => {
    console.log("remote.js load");
    hookAll();
});

// Expand collapse sections
$(document).on("click", "*[data-expand-source]", (event) => {
    const targetName = $(event.currentTarget).attr("data-expand-source");

    if ($(event.currentTarget).attr("data-expand-effect") === 'slide') {
        $(`*[data-expand-target="${targetName}"]`).slideToggle();
    } else {
        $(`*[data-expand-target="${targetName}"]`).toggleClass("hidden");
    }


    if ($(event.currentTarget).attr("data-expand-hide-on-expand")) {
        $(event.currentTarget).toggleClass("hidden");
    }
});

// Reveal/hide siblings on click
$(document).on("click", "*[data-toggle-sibling]", (event) => {
    const selector = $(event.currentTarget).attr("data-toggle-sibling");
    $(event.currentTarget).siblings(selector).slideToggle();
});

// Show on change
$(document).on("change", "*[data-change-source]", (event) => {
    const targetName = $(event.currentTarget).attr("data-change-source");

    console.log("CHANGE", targetName);

    if ($(event.currentTarget).attr("data-change-effect") === 'slide') {
        $(`*[data-change-target="${targetName}"]`).slideToggle();
    } else {
        $(`*[data-change-target="${targetName}"]`).toggleClass("hidden");
    }
});

// Fade out
document.addEventListener("turbolinks:load", () => {
    $("*[data-fade-out]").each((index, element) => {
        let delay = $(element).attr("data-fade-out");
        if (!delay) {
            delay = 3000;
        }
        window.setTimeout(() => {
            $(element).fadeOut();
        }, parseInt(delay));
    });
});

// Load content on click
$(document).on("click", "*[data-fetch-on-click-url]", (event) => {
    const url = $(event.currentTarget).attr("data-fetch-on-click-url");

    $.get(url, (data) => {
        $("body").append(data);
    });
});

// Show modals on click
$(document).on("click", "*[data-click-open-modal]", (event) => {
    const id = $(event.currentTarget).attr("data-click-open-modal");

    $(".guild-modal-container").addClass('hidden');
    $(`#${id}`).removeClass('hidden');
});

// Mobile sharing
export const revealShare = () => {
    if (navigator.share) {
        $("*[data-share-url]").each((index, element) => {
            $(element).removeClass('hidden');
        });
    }
}

document.addEventListener("turbolinks:load", () => {
    revealShare();
});

$(document).on('click', "*[data-share-url]", (event) => {
    if (navigator.share) {
        const url = $(event.currentTarget).attr('data-share-url');
        const title = $(event.currentTarget).attr('data-share-title');
        const text = $(event.currentTarget).attr('data-share-text');
        navigator.share({
            title: title || 'Platform',
            text: text || '',
            url: url,
        })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
    }
});

// View update using WebSockets
import consumer from "../channels/consumer";

document.addEventListener("turbolinks:load", () => {
    setupWebsocketRefresh();
});

const setupWebsocketRefresh = () => {
    $("*[data-websocket-refresh]").each((index, element) => {
        const url = $(element).attr("data-source-url");
        const dataUri = $(element).attr("data-uri");

        if (!element._ws_subscription) {
            element._ws_subscription = consumer.subscriptions.create({
                channel: "ViewUpdateChannel",
                uri: dataUri || url
            }, {
                connected() {
                    $(element).attr("data-websocket-refreshing", true);
                    console.log("ViewUpdateChannel connected", this);
                },
                disconnected() {
                    $(element).removeAttr("data-websocket-refreshing");
                    element._ws_subscription = undefined;
                    console.log("ViewUpdateChannel disconnected");
                },
                received(data) {
                    console.log("ViewUpdateChannel received for", url, data);
                    if ($(element).attr("data-websocket-refresh-content") === 'true') {
                        remoteRefreshContent(element);
                    } else {
                        remoteRefresh(element);
                        if (element._ws_subscription) {
                            element._ws_subscription.unsubscribe();
                            element._ws_subscription = undefined;
                        }
                    }

                }
            });
        }
    });
}

// Post individual form fields on change
$(document).on("change", "*[data-post-on-change-url]", (event) => {
    const url = $(event.currentTarget).attr("data-post-on-change-url");
    const name = $(event.currentTarget).attr("name");
    const value = $(event.currentTarget).val();

    console.log("Post on change", url, name, value);

    // $.post(url, {[name]: value});

    $.ajax({
        type: "PATCH",
        url: url,
        data: {[name]: value}
    });
});

// Browser notifications
export const requestBrowserNotifications = () => {
    // Check if the browser supports notifications
    if ("Notification" in window) {
        if (Notification.permission === "granted") {
            console.log("Notification already granted");
        } else {
            // Request permission for notifications
            Notification.requestPermission().then(function (result) {
                $.post("/guild/users/update_me", {
                    user: {browser_notifications: result},
                    no_redirect: true
                }, (response) => {
                    console.log("Browser notifications", result, response);
                });
            });
        }
    } else {
        console.log("This browser does not support Notification");
    }
}

export const browserNotification = (title, body, timeout) => {
    if (!("Notification" in window)) {
        return;
    }

    Notification.requestPermission().then(function (result) {
        if (result === 'granted') {
            let notification = new Notification(title, {
                body: body
            });
            if (timeout) {
                setTimeout(notification.close.bind(notification), timeout);
            }
            console.log("Notification sent");
        }

        // Update record on server
        $.post("/guild/users/update_me", {
            user: {browser_notifications: result},
            no_redirect: true
        }, (response) => {
            console.log("Browser notifications", result, response);
        });
    });
}

export const rotatePageTitle = (text) => {
    window._titleRotator = {};
    window._titleRotator.originalText = document.title;
    window._titleRotator.interval = setInterval(() => {
        document.title = document.title === window._titleRotator.originalText ? text : window._titleRotator.originalText;
    }, 2000);
}

export const cancelRotatePageTitle = () => {
    if (window._titleRotator) {
        clearInterval(window._titleRotator.interval);
        document.title = window._titleRotator.originalText;
        window._titleRotator = undefined;
    }
}
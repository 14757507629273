document.addEventListener("turbolinks:load", () => {
    $("*[data-effect-typing]").each((index, element) => {
        const items = $(element).children();

        let originalText = $(items[0]).text();

        let itemIndex = 0;
        let splitPoint = 0;
        let pauseCount = 0;
        let frame = 0;

        window.setInterval(() => {
            if (splitPoint < originalText.length) {
                splitPoint += 1;
            }

            let text = originalText.substring(0, splitPoint);

            if (frame % 20 < 10) {
                text += "<span>_</span>";
            } else {
                text += "<span style='opacity: 0;'>_</span>";
            }
            $(element).html(text);

            if (splitPoint === originalText.length) {
                pauseCount += 1;
                if (pauseCount > 50) {
                    splitPoint = 0;
                    pauseCount = 0;
                    itemIndex += 1;

                    if (itemIndex >= items.length) {
                        itemIndex = 0;
                    }
                    originalText = $(items[itemIndex]).text();
                }
            }

            frame += 1
        }, 50);
    });
});

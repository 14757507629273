const Followable = {};

(() => {

  const register = Followable.register = () => {
    $(".inline-follow-action").click((event) => {
      $.post($(event.currentTarget).attr("data-url"), (data) => {
        console.log(data);
        $(event.target).html(data.following ? "&check;&nbsp;Following" : "Follow");
        
        $(event.target).siblings(".inline-follow-count").first().html(`${data.count} followers`);
        
        Remote.refresh(".guild-follower-list");
      });
    });
  }
    
})();

document.addEventListener("turbolinks:load", () => {
  console.log("followable.js load");
  Followable.register();
});

console.log("forms.js load");

var timer = undefined;

// Save any changes in text fields and textareas on forms incrementally
$(document).on('keyup', "form.smart-form-live input[type='text']:not([smart-form-ignore]),form.smart-form-live textarea:not([smart-form-ignore])", (event) => {
	console.log("Change", event);
	
	if (timer) {
		console.log("Save pending");
		return;
	}

	const form = $(event.currentTarget).parents("form");
	const action = $(form[0]).attr('action');

	timer = window.setTimeout(() => {
		const fieldName = $(event.currentTarget).attr('name');
		const fieldValue = $(event.currentTarget).val();
		const data = {};

		data[fieldName] = fieldValue;
		data.incremental = true;

		const successIndicator = form.find('.smart-form-saved-message');
		const errorIndicator = form.find('.smart-form-error-message');
		const savingIndicator = form.find('.smart-form-saving-message');
		
		savingIndicator.show();
		
		$.ajax({
			url: action,
			method: 'PATCH',
			data: data,
			success: () => {
				successIndicator.show();
				errorIndicator.hide();
				window.setTimeout(() => {
					successIndicator.fadeOut();
				}, 1000);
			},
			error: () => {
				successIndicator.hide();
				errorIndicator.show();
			},
			complete: () => {
				savingIndicator.hide();
			}
		});
		
		timer = undefined;
	}, 2000);
});

// Nameable selector
$(document).on("click", ".guild-nameable-selector .guild-nameable-pill", (event) => {
	const selector = $(event.currentTarget).parents(".guild-nameable-selector");

	if (selector.attr("data-multiselect") == 'true') {
		$(event.currentTarget).toggleClass("guild-nameable-pill-selected");
	} else {
		selector.find(".guild-nameable-pill").removeClass("guild-nameable-pill-selected");
		$(event.currentTarget).addClass("guild-nameable-pill-selected");
	}

	const form = selector.parents(".guild-edge-form");
	form.attr("data-commentable-type", $(event.currentTarget).attr("data-nameable-type"));
	form.attr("data-commentable-id", $(event.currentTarget).attr("data-nameable-id"));
	
	const selectedIds = [];
	const selectedTypes = [];
	const pills = selector.find(".guild-nameable-pill");
	for (let i = 0; i < pills.length; i++) {
		if ($(pills[i]).hasClass("guild-nameable-pill-selected")) {
			selectedTypes.push($(pills[i]).attr("data-nameable-type"))
			selectedIds.push($(pills[i]).attr("data-nameable-id"))
		}
	}

	selector.find('input[name="nameable_ids"]').val(selectedIds);
});

$(document).on("click", ".guild-limited-height-expander", (event) => {
	$(event.currentTarget).parent().siblings(".guild-limited-height-expandable").css({maxHeight: 'none'});
	$(event.currentTarget).parent().css({display: 'none'});
});

// Scope selector
$(document).on("click", ".guild-scope-selection", (event) => {
	const selected = $(event.currentTarget);
	const selector = selected.parents(".guild-scope-selector");
	
	const selection_id = selected.attr("data-scope-selection-name");
	
	console.log("Selector", selected, selector, selection_id);
	
	selector.find('.guild-scope-selection').removeClass('guild-scope-selected');
	selected.addClass('guild-scope-selected');
	
	$(`*[data-scope-selector-target]`).addClass('hidden');
	$(`*[data-scope-selector-target="${selection_id}"]`).removeClass('hidden');
});

$(document).on("submit", "form", (event) => {
	const el = $(event.currentTarget).find("input[type='submit'][data-disable-on-click]");
	console.log("Submit", el.length);
	el.attr("disabled", "disabled");
	el.attr("value", "Loading...");
});

// Edit in place
document.addEventListener("turbolinks:load", () => {
	$("*[data-edit-in-place-name]").each((index, element) => {
		$(element).append('<span class="guild-edit-in-place-action material-icons">edit</span>');
	});
});

$(document).on('click', '*[data-edit-in-place-name]', (event) => {
	const element = $(event.currentTarget);
	const name = element.attr('data-edit-in-place-name');
	let value = element.attr('data-edit-in-place-value');
	if (!value) {
		value = element.text();
	}

	element.css('display', 'none');

	const input = $('<input class="data-edit-in-place-editor" type="text" />');
	input.insertAfter(element);

	input.val(value);
	input.focus();
});
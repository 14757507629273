require("jquery")
import {remoteRefreshClass} from './remote'

function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
        console.error('Async: Could not copy text: ', err);
    });
}


function setupGuildSignup() {
    $(".guild-role-checkbox").change((event) => {
        $(event.target).parents('.guild-role-choice').first().children(".guild-role-subchoices").first().toggleClass("hidden");
    });
}

function setupArtifacts() {
    const element = document.querySelector("#guild-artifact-form");
    if (element) {
        element.addEventListener("ajax:success", (event) => {
            const [_data, _status, xhr] = event.detail;
            const newsList = document.getElementById("guild-news-list");
            newsList.insertAdjacentHTML("afterbegin", xhr.responseText);
            $(event.target).addClass("hidden");
            setupArtifactsDelete();
        });
        element.addEventListener("ajax:error", () => {
            console.log("Error in post artifact");
        });
    }

    $(".guild-post-artifact-action").click((event) => {
        $(event.target).siblings("#guild-artifact-form").first().toggleClass("hidden");
    });
}

function setupArtifactsDelete() {
    $(".guild-delete-artifact-action").on("ajax:success", (event) => {
        $(event.target).parents(".news_item").first().remove();
    });
}

function setupFundraises() {
    $(".guild-fundraise-apply-action").click((event) => {
        $(event.target).siblings(".guild-fundraise-form-container").first().toggleClass("hidden");
    });
}


function setupJobApplications() {
    $(".guild-apply-action").click((event) => {
        $(event.target).siblings(".guild-recommend-form-container").first().addClass("hidden");
        $(event.target).siblings(".guild-apply-form-container").first().toggleClass("hidden");
    });
    $(".guild-recommend-action").click((event) => {
        $(event.target).siblings(".guild-apply-form-container").first().addClass("hidden");
        $(event.target).siblings(".guild-recommend-form-container").first().toggleClass("hidden");
    });
}


function showTab(sectionName) {
    $(".guild-tab").removeClass("guild-tab-selected");
    $(`#${sectionName}`).addClass("guild-tab-selected");
}

function setupTabs() {
    const selected = $(".guild-tab-bar-selected").first();
    if (selected.length > 0) {
        const sectionName = selected.attr('data-tab-id');
        showTab(sectionName);
    }

    $(".guild-tab-bar-option").click((event) => {
        let option = $(event.target);
        if (!option.hasClass("guild-tab-bar-option")) {
            option = option.parents(".guild-tab-bar-option").first();
        }

        $(".guild-tab-bar-option").removeClass("guild-tab-bar-selected");
        option.addClass("guild-tab-bar-selected");
        const sectionName = option.attr('data-tab-id');
        showTab(sectionName);
    });
}

function setupClipboard() {
    $(document).on("click", ".copy_to_clipboard .copy_to_clipboard_action", (event) => {
        const text = $(event.target).parent(".copy_to_clipboard").children(".copy_to_clipboard_source").first().text();
        copyTextToClipboard(text);
        $(event.target).parent(".copy_to_clipboard").find(".copy_to_clipboard_success").first().removeClass("hidden");
    });
}

function setupFollow() {
    $(document).on("ajax:success", ".follow-button", (event) => {
        const [data, status, xhr] = event.detail;
        const button = $(event.target).children("input").first();
        if (data.following) {
            button.removeClass("guild-button-secondary");
            button.addClass("guild-button");
            button.attr("value", "\u2713 Following");
        } else {
            button.removeClass("guild-button");
            button.addClass("guild-button-secondary");
            button.attr("value", "Follow");
        }
        remoteRefreshClass(".guild-follower-list");
    });
}

function setupLike() {
    $(document).on("ajax:success", ".like-button", (event) => {
        const [data, status, xhr] = event.detail;
        console.log(data);
        const icon = $(event.target).children(".like-icon").first();
        const count = $(event.target).children(".like-count").first();
        count.html(data.count);
        if (data.liked) {
            icon.html('favorite');
        } else {
            icon.html('favorite_border');
        }
    });
}

function setupHooks() {
    setupClipboard();
    setupFollow();
    setupLike();
    setupTabs();
    setupJobApplications();
    setupArtifacts();
    setupArtifactsDelete();
    setupGuildSignup();
    setupFundraises();
}

document.addEventListener("turbolinks:load", () => {
    console.log("Guild.js load");
    setupHooks();
});

$(document).on('click', ".guild-modal-close-button", (event) => {
    $(event.target).parents(".guild-modal-container").first().addClass("hidden");
});

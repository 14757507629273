document.addEventListener("turbolinks:load", () => {
  console.log("home.js load");
  
  const num = 10;
  
  let feedItems = $(".guild-all-update-stream .guild-update").toArray();
  
  for (let i = 0; i < num; i++) {
    $(feedItems[i]).hide();
  }
  
  let i = num - 1;
  
  var interval = window.setInterval(() => {
    $(feedItems[i]).fadeIn("slow");
    i = i - 1;
    if (i < 0) {
      window.clearInterval(interval);
    }
  }, 5000);
});